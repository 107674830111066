import { createSignal } from "solid-js";
import { useNavigate } from "@solidjs/router";
import "./TestForm1.scss";

const TestForm1 = () => {
  const [email, setEmail] = createSignal("");
  const [firstName, setFirstName] = createSignal("");
  const [lastName, setLastName] = createSignal("");
  const [phoneNumber, setPhoneNumber] = createSignal("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate(`/lead_gen_page_1?email=${email()}`);
    window.location.href = `/lead_gen_page_1?email=${email()}`;
  };

  document.addEventListener("submit", async function (e) {
    e.preventDefault();
    const form = e.target;
    const email = form.querySelector('input[type="email"]').value;
    window.location.href = `/lead_gen_page_1?email=${encodeURIComponent(
      email
    )}`;
  });

  return (
    <div className="TestForm1">
      <form>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email()}
            onInput={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>First Name:</label>
          <input
            type="text"
            value={firstName()}
            onInput={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName()}
            onInput={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            value={phoneNumber()}
            onInput={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default TestForm1;
